import React from 'react';
import { ArrowCircleUpIcon, Button, styled, SxProps, Typography } from '@methanesat/ui-components';
import { useTranslate } from '../../hooks';

interface ExploreDataPortalButtonProps {
    close: () => void;
    sx?: SxProps;
}

// background gradient - custom, not-quite linear gradient
const background = `linear-gradient(
  90deg, 
  rgb(249, 237, 59) 0%, 
  rgb(249, 237, 59) 5%, 
  rgb(240, 196, 55) 30%,
  rgb(232, 156, 51) 60%,
  rgb(223, 116, 47) 80%,
  rgb(215, 76, 43) 100%
)`;

const StyledButton = styled(Button)(({ theme }) => ({
    background,
    [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(1, 'auto'),
        padding: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
        margin: theme.spacing(1.25, 'auto'),
        padding: theme.spacing(1.5)
    },
    [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(2, 'auto'),
        padding: theme.spacing(1.6)
    },
    ...theme.mixins.gradientButton
}));

const ExploreDataPortalButton = ({ close, sx }: ExploreDataPortalButtonProps) => {
    const t = useTranslate();
    return (
        <StyledButton
            data-testid="explore-data-portal-button"
            onClick={close}
            fullWidth
            sx={Array.isArray(sx) ? sx : [sx]}
        >
            <Typography
                sx={{
                    fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem',
                        md: '1rem',
                        lg: '1.15rem',
                        xl: '1.2rem'
                    },
                    fontWeight: 450,
                    marginLeft: '-14%'
                }}
            >
                {t('emissionsMapPage.introModal.buttonText')}
            </Typography>
            <ArrowCircleUpIcon
                className="arrow-icon"
                sx={{
                    rotate: '90deg',
                    color: 'white',
                    position: 'absolute',
                    right: '3%',
                    transition: 'right 0.3s ease-in-out',
                    fontSize: {
                        xs: '1.25rem',
                        sm: '1.75rem',
                        md: '2rem',
                        lg: '2.1rem',
                        xl: '2.25rem'
                    }
                }}
            />
        </StyledButton>
    );
};

export default ExploreDataPortalButton;
