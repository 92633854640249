import { useSelector } from 'react-redux';

import {
    Box,
    CloseIcon,
    Fade,
    formatLocaleDate,
    Grid,
    IconButton,
    MarkdownFormatter,
    Modal,
    Typography
} from '@methanesat/ui-components';
import ExploreDataPortalButton from './ExploreDataPortalButton';
import { colorArrayToRgb, grey } from '@methanesat/colors';
import { useTranslate } from '../../hooks';
import { selectPlatform } from '../../reducers';
import { getLatestCollectionDate } from '../../consts';
import { getBuildInfoForDisplay, SHOW_DATA_COLLECTION_HEADLINE } from '../../environmentVariables';

type IntroModalProps = {
    open: boolean;
    action: (action: 'close' | 'closeAndOpenLegend') => void;
};

export const IntroModal = (props: IntroModalProps) => {
    const t = useTranslate();
    const platform = useSelector(selectPlatform);
    const latestCollectionDateFormatted = formatLocaleDate(getLatestCollectionDate(platform).value);

    // Using dangerouslySetInnerHTML to render the trademark symbol as a superscript without the bold styling.
    const headerText = t('emissionsMapPage.introModal.header').replace('™', '<sup>™</sup>');
    return (
        <Modal open={props.open} onClose={() => props.action('close')} data-testid="intro-modal">
            <Fade in={props.open}>
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.up('xs')]: {
                            height: `80vh`
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '75%',
                            maxHeight: '60vh',
                            height: 'unset'
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '60%',
                            maxHeight: '75%'
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: '54%',
                            maxHeight: '60%'
                        },
                        [theme.breakpoints.up('xl')]: {
                            width: '800px'
                        },
                        ...theme.mixins.modalBox
                    })}
                >
                    <Grid
                        container
                        sx={{
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: { xs: 'space-between', sm: 'center' },
                            flexWrap: 'nowrap',
                            height: { xs: '100%', sm: 'auto' }
                        }}
                    >
                        {/* Left Panel */}
                        <Grid
                            item
                            xs={12}
                            sm={6.75}
                            sx={(theme) => ({
                                flexBasis: { xs: 'max-content', sm: 'auto' },
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: {
                                    xs: `${theme.mixins.modalBox.borderRadius}px ${theme.mixins.modalBox.borderRadius}px 0 0`,
                                    sm: `${theme.mixins.modalBox.borderRadius}px 0 0 ${theme.mixins.modalBox.borderRadius}px`
                                }
                            })}
                        >
                            <Grid container rowGap={1} padding={3}>
                                {/* Header */}
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h2"
                                        sx={(theme) => ({
                                            paddingBottom: theme.spacing(1),
                                            textShadow: '0.525px 0',
                                            margin: theme.spacing(-2, 'auto', -1.5, 0),

                                            sup: {
                                                fontWeight: theme.typography.fontWeightRegular,
                                                position: 'relative',
                                                top: theme.spacing(0.4)
                                            }
                                        })}
                                        data-testid="intro-modal-header"
                                        dangerouslySetInnerHTML={{ __html: headerText }}
                                    ></Typography>
                                </Grid>

                                {/* Subheader */}
                                <Grid item xs={12}>
                                    <Typography
                                        variant="subtitle1"
                                        sx={(theme) => ({
                                            fontWeight: theme.typography.fontWeightBold,
                                            textShadow: '0.55px 0',
                                            marginRight: 'auto',
                                            marginBottom: {
                                                sm: theme.spacing(0.75),
                                                md: theme.spacing(1),
                                                lg: theme.spacing(1.25)
                                            }
                                        })}
                                    >
                                        {t('emissionsMapPage.introModal.subHeader')}
                                    </Typography>
                                </Grid>

                                {/* Description */}
                                {t('emissionsMapPage.introModal.description')
                                    .split('\n')
                                    .map((paragraph, idx) => {
                                        return (
                                            <Grid item xs={12} key={`description-${idx}`}>
                                                <MarkdownFormatter markdown={paragraph} />
                                            </Grid>
                                        );
                                    })}

                                {/* `Explore Data Portal` Button */}
                                <Grid item xs={12}>
                                    <ExploreDataPortalButton
                                        data-testId={''}
                                        close={() => props.action('closeAndOpenLegend')}
                                        sx={{
                                            width: { xs: '100%', sm: '70%' },
                                            maxWidth: { xs: '200px', sm: 'unset' },
                                            minWidth: 'fit-content'
                                        }}
                                    />
                                </Grid>

                                {/* FAQ */}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
                                >
                                    <Typography
                                        variant="caption"
                                        color={colorArrayToRgb(grey)}
                                        sx={{
                                            lineHeight: '0.5rem',
                                            marginLeft: 'auto'
                                        }}
                                    >
                                        {getBuildInfoForDisplay()}
                                    </Typography>
                                </Grid>

                                {/* `X` button to close the modal */}
                                <IconButton
                                    data-testid="close-modal-button"
                                    onClick={() => props.action('close')}
                                    sx={(theme) => ({
                                        color: {
                                            xs: theme.palette.text.primary,
                                            sm: 'white'
                                        },
                                        position: 'absolute',
                                        top: theme.spacing(1),
                                        right: theme.spacing(1),
                                        zIndex: 1000
                                    })}
                                >
                                    <CloseIcon sx={{ fontSize: '1.5rem' }} />
                                </IconButton>
                            </Grid>
                        </Grid>

                        {/* Right Panel */}
                        <Grid
                            item
                            xs={12}
                            sm={5.25}
                            sx={(theme) => ({
                                minHeight: {
                                    xs: 'auto',
                                    sm: '150px'
                                },
                                backgroundImage: `url('/img/MethaneSAT_rendering.jpg')`,
                                backgroundSize: {
                                    xs: 'cover',
                                    sm: 'auto 100%'
                                },
                                backgroundPosition: {
                                    xs: 'top right',
                                    sm: 'top right -120px',
                                    md: 'top right -150px'
                                },
                                backgroundRepeat: 'no-repeat',
                                position: 'relative',
                                width: {
                                    xs: '100%',
                                    sm: 'auto'
                                },
                                borderRadius: {
                                    xs: `0 0 ${theme.mixins.modalBox.borderRadius}px ${theme.mixins.modalBox.borderRadius}px`,
                                    sm: `0 ${theme.mixins.modalBox.borderRadius}px ${theme.mixins.modalBox.borderRadius}px 0`
                                }
                            })}
                        >
                            {/* Latest Data Collection Text */}
                            {SHOW_DATA_COLLECTION_HEADLINE && (
                                <Grid
                                    container
                                    sx={(theme) => ({
                                        width: { xs: '90%', sm: '65%' },
                                        flexDirection: 'column',
                                        padding: theme.spacing(3)
                                    })}
                                    justifyContent="flex-start"
                                    display="flex"
                                    gap={1}
                                >
                                    <Grid item xs>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                color: 'white',
                                                fontWeight: 500
                                            }}
                                        >
                                            {t('emissionsMapPage.introModal.latestDataCollection')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                color: 'white',
                                                fontWeight: 400
                                            }}
                                        >
                                            {latestCollectionDateFormatted}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};
